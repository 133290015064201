import { RedesSociales } from '../components/Banners/RedesSociales'
import { Footer } from '../components/UI/Footer'
import { Navbar } from '../components/navbar/Navbar'

export const Faq = () => {
	return (
		<>
			<Navbar />
			<RedesSociales />
			<Footer />
		</>
	)
}
