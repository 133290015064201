export const videosMock = [
	{
		title: 'LAPT 2023',
		image: 'https://i.ibb.co/wdpqPqB/viajes-latin-america.jpg',
		videos: [
			'https://www.youtube.com/watch?v=ob4rYpMNQSI&t=33s&ab_channel=GrupoCheckCheck',
			'https://www.youtube.com/watch?v=jVWp1tqfjP8&t=14s&ab_channel=GrupoCheckCheck',
			'https://www.youtube.com/watch?v=-Ew8iDO1gSI&t=7s&ab_channel=GrupoCheckCheck',
			'https://www.youtube.com/watch?v=kWmVHLT-ypE&t=330s&ab_channel=GrupoCheckCheck',
		],
	},
	{
		title: 'BSOP 2022',
		image: 'https://i.ibb.co/CBfwky3/viajes-bsop.jpg',
		videos: [
			'https://www.youtube.com/watch?v=kDaP40Dpglw&t=57s&ab_channel=GrupoCheckCheck',
			'https://www.youtube.com/watch?v=DK5ONLHy5gU&t=1s&ab_channel=GrupoCheckCheck',
			'https://www.youtube.com/watch?v=36snMBjPWMc&t=284s&ab_channel=GrupoCheckCheck',
			'https://www.youtube.com/watch?v=eDIHJFzdrak&t=1s&ab_channel=GrupoCheckCheck',
			'https://www.youtube.com/watch?v=6krx3v6H7v0&t=4s&ab_channel=GrupoCheckCheck',
			'https://www.youtube.com/watch?v=CAx-aCE8RT0&t=1s&ab_channel=GrupoCheckCheck',
		],
	},
	{
		title: 'CAP ROSARIO',
		image: 'https://i.ibb.co/PTJp0DM/viajes-principal-rec.jpg',
		videos: [
			'https://www.youtube.com/watch?v=-PL2g-ihkGo',
			'https://www.youtube.com/watch?v=SLLjKAmBYns',
			'https://www.youtube.com/watch?v=cWzY65unTIg&t=29s',
			'https://www.youtube.com/watch?v=ErwJOeSOFR4&t=480s',
			'https://www.youtube.com/watch?v=8ZlBbjpoPV4&t=3s',
			'https://www.youtube.com/watch?v=43Gj28CICKA',
		],
	},
]